// @flow

/**
 * Module dependencies.
 */

import { Container } from 'components/core/layout';
import type { TemplateProps } from 'types/template';
import { color, media } from 'react-components/styles';
import { graphql, useStaticQuery } from 'gatsby';
import { prop } from 'styled-tools';
import Footer from 'components/footer';
import Metatags from 'components/metatags';
import Navbar from 'components/navbar';
import PageContainer from 'components/page-container';
import React, { type Node } from 'react';
import RoadmapHeader from 'components/roadmap/roadmap-header';
import RoadmapTabs from 'components/roadmap/roadmap-tabs';
import Separator from 'components/core/separator';
import SubscriptionForm from 'components/core/subscription-form';
import styled from 'styled-components';

/**
 * Page query.
 */

const pageQuery = graphql`
  query {
    bubblesLeftImage: file(
      relativePath: { eq: "images/roadmap-bubbles-left.png" }
    ) {
      childImageSharp {
        fixed(width: 345) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    bubblesRightImage: file(
      relativePath: { eq: "images/roadmap-bubbles-right.png" }
    ) {
      childImageSharp {
        fixed(width: 297) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    separatorImage: file(
      relativePath: { eq: "images/about-us-separator.png" }
    ) {
      childImageSharp {
        fixed(width: 40) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  --gradient: linear-gradient(231.15deg, ${color('white')} 0%, ${color('grey100')} 100%);

  background-image: var(--gradient);
  position: relative;

  ${media.min('ms')`
    background-image:
      url(${prop('bubblesRightImage')}),
      url(${prop('bubblesLeftImage')}),
      var(--gradient);
    background-position:
      right -95px top 148px,
      left -62px bottom 121px,
      0 0;
    background-repeat: no-repeat;
  `}

  ${media.min('md')`
    background-position:
      right -95px top 308px,
      left -62px bottom 121px,
      0 0;
  `}
`;

/**
 * `FooterWrapper` styled component.
 */

const FooterWrapper = styled.div`
  background-color: ${color('blue800')};
  padding-top: 7.5rem;
`;

/**
 * `Roadmap` component.
 */

function Roadmap({ pageContext }: TemplateProps): Node {
  const { content } = pageContext;
  const {
    bubblesLeftImage,
    bubblesRightImage,
    separatorImage
  } = useStaticQuery(pageQuery);

  return (
    <PageContainer>
      <Metatags {...pageContext?.metatags} />

      <Wrapper
        bubblesLeftImage={bubblesLeftImage?.childImageSharp?.fixed.src}
        bubblesRightImage={bubblesRightImage?.childImageSharp?.fixed.src}
      >
        <Navbar />

        <RoadmapHeader
          lastUpdated={content?.lastUpdated}
          lead={content?.lead}
          separatorImage={separatorImage?.childImageSharp?.fixed}
          title={content?.title}
        />

        <RoadmapTabs roadmap={content?.roadmap} />
      </Wrapper>

      <FooterWrapper>
        <Container>
          <SubscriptionForm />

          <Separator />
        </Container>

        <Footer />
      </FooterWrapper>
    </PageContainer>
  );
}

/**
 * Export `Roadmap` component.
 */

export default Roadmap;
