// @flow

/**
 * Module dependencies.
 */

import { Icon } from 'react-components/media';
import { Type } from 'react-components/typography';
import { color, media, units } from 'react-components/styles';
import { ifNotProp, ifProp, switchProp, theme } from 'styled-tools';
import Badge from 'components/core/badge';
import React, { Fragment, type Node, useEffect, useRef, useState } from 'react';
import checkIcon from 'assets/svg/check.svg';
import clockIcon from 'assets/svg/clock.svg';
import inProgressIcon from 'assets/svg/in-progress.svg';
import styled, { css } from 'styled-components';

/**
 * Section statuses.
 */

const sectionStatuses = {
  done: 'done',
  inProgress: 'inProgress',
  todo: 'todo'
};

/**
 * `SectionStatus` type.
 */

type SectionStatus = $Keys<typeof sectionStatuses>;

/**
 * `RoadmapEntry` type.
 */

type RoadmapEntry = {|
  badgeColorTheme: string,
  badgeText: string,
  id: number | string,
  title: string
|};

/**
 * `RoadmapSection` type.
 */

type RoadmapSection = {|
  entries: Array<RoadmapEntry>,
  id: number | string,
  status: SectionStatus,
  title: string
|};

/**
 * `RoadmapYear` type.
 */

type RoadmapYear = {|
  id: number | string,
  sections: Array<RoadmapSection>,
  year: string
|};

/**
 * `Props` type.
 */

type Props = {|
  roadmap: Array<RoadmapYear>
|};

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  margin: 0 auto;
  max-width: ${theme('containerMaxWidth')};
  padding-bottom: ${units(8)};

  ${media.min('ms')`
    display: grid;
    grid-row-gap: ${units(3)};
    grid-template-areas: '. years . quarters entries .';
    grid-template-columns: 6.25vw 1fr 1fr 1fr 7fr 6.25vw;
    grid-template-rows: repeat(4, max-content);
    padding-bottom: ${units(20)};
    padding-top: ${units(4)};
  `}

  ${media.min('md')`
    grid-template-columns: 2fr 1fr 1fr 1fr 5fr 2fr;
  `}
`;

/**
 * `YearsWrapper` styled component.
 */

const YearsWrapper = styled.div`
  align-items: flex-end;
  display: flex;
  height: max-content;
  min-height: ${units(5)};
  overflow-y: scroll;
  position: sticky;
  scrollbar-width: none;
  top: 0;
  z-index: 1;

  ${ifProp('isSticky', css`
    background-image: linear-gradient(231.15deg, #ffffff 0%, #f7f9fe 100%);
  `)}

  &::-webkit-scrollbar {
    display: none;
  }
  
  ${media.min('ms')`
    background: none;
    grid-area: years;
    grid-row-end: -1;
    overflow-y: initial;
  `}
`;

/**
 * `YearsList` styled component.
 */

const YearsList = styled.div`
  align-items: end;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-column-gap: ${units(3)};
  padding: 0 6.25vw;
  position: relative;

  &::before {
    border-bottom: 1px solid ${color('blue200')};
    bottom: 0;
    content: '';
    left: 6.25vw;
    position: absolute;
    right: 6.25vw;
  }

  ${media.min('ms')`
    grid-auto-flow: initial;
    grid-auto-columns: 1fr;
    grid-row-gap: ${units(2.5)};
    padding: 14px 0 0;
    width: 100%;

    &::before {
      content: none;
      display: none;
    }
  `}
`;

/**
 * `Year` styled component.
 */

const Year = styled.button`
  ${theme('typography.styles.h4')}

  appearance: none;
  background: transparent;
  border: 0;
  color: ${color('blue500')};
  cursor: pointer;
  padding: 0 0 ${units(1)};
  position: relative;
  text-align: initial;
  transition: ${theme('transitions.default')};
  transition-property: color, font-size, font-weight, letter-spacing, line-height;
  width: 100%;

  &::before {
    background-color: ${color('blue200')};
    bottom: 0;
    content: '';
    height: 2px;
    left: 0;
    position: absolute;
    transform: scale(0.25, 0.50);
    transform-origin: bottom left;
    transition: ${theme('transitions.default')};
    transition-property: background-color, transform;
    width: 100%;
  }

  &[aria-selected="true"] {
    ${theme('typography.styles.h3')}

    color: ${color('green500')};
    
    &::before {
      background-color: ${color('green500')};
      transform: scale(0.66, 1);
    }
  }

  ${media.min('ms')`
    &[aria-selected="true"]::before {
      transform: scale(1);
    }
  `}
`;

/**
 * `YearContent` styled component.
 */

const YearContent = styled.div`
  display: grid;
  grid-template-columns: 6.25vw 1fr 6.25vw;

  ${media.min('ms')`
    display: contents;
  `}
`;

/**
 * Status colors.
 */

const statusColors = {
  done: color('green500'),
  inProgress: color('blue700'),
  todo: color('blue800')
};

/**
 * `SectionMarker` styled component.
 */

const SectionMarker = styled.div`
  align-items: center;
  display: grid;
  grid-column: 2;
  grid-column-gap: ${units(1)};
  grid-template-columns: repeat(2, max-content);
  grid-template-rows: minmax(${units(4)}, max-content);
  padding-bottom: ${units(1.5)};
  padding-top: ${units(4)};

  ${media.min('ms')`
    grid-column: quarters;
    grid-template-columns: repeat(2, 1fr);
    padding-bottom: 0;
    padding-top: ${units(2)};
    position: relative;

    ${ifNotProp('isLast', css`
      &::before {
        border-right: 1px solid ${switchProp('status', statusColors)};
        content: '';
        height: calc(100% - 8px);
        position: absolute;
        // ((width - gap) / columns) - half icon size
        // ((100% - 8px) / 2) - 12px
        right: calc(50% - 16px);
        top: 48px;
      }
    `)}
  `}
`;

/**
 * `MarkerIcon` styled component.
 */

const MarkerIcon = styled(Icon)`
  overflow: visible;

  [data-animate="spin"] {
    animation: 2s ease-in-out infinite ${theme('keyframes.spin')};
    transform-origin: center;
  }
`;

/**
 * `SectionEntries` styled component.
 */

const SectionEntries = styled.div`
  background-color: ${color('white')};
  border: 1px solid ${color('blue200')};
  border-bottom-left-radius: 12px;
  border-top-left-radius: 12px;
  grid-column: 1 / -1;
  margin-left: ${units(1)};
  padding-left: calc(6.25vw - ${units(1)});
  padding-right: 6.25vw;

  ${media.min('ms')`
    border-radius: 12px;
    grid-column: entries;
    padding: 0 ${units(3)};
  `}
`;

/**
 * `SectionEntry` styled component.
 */

const SectionEntry = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: ${units(1)};
  min-height: ${units(8)};
  padding: ${units(2)} 0;

  &:not(:last-child) {
    border-bottom: 1px solid ${color.transparentize('blue200', 0.5)};
  }

  ${media.min('sm')`
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  `}

  ${media.min('ms')`
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  `}

  ${media.min('md')`
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  `}
`;

/**
 * `SectionEntryText` styled component.
 */

const SectionEntryText = styled(Type.Small).attrs({ as: 'p' })`
  color: ${color('blue700')};
  font-weight: 600;

  ${media.min('ms')`
    ${theme('typography.styles.p')}

    font-weight: 600;
  `}
`;

/**
 * `EntryBadge` styled component.
 */

const EntryBadge = styled(Badge)`
  flex-shrink: 0;
  text-transform: uppercase;
`;

/**
 * Status icons.
 */

const statusIcons = {
  done: checkIcon,
  inProgress: inProgressIcon,
  todo: clockIcon
};

/**
 * `RoadmapTabs` component.
 */

function RoadmapTabs(props: Props): Node {
  const { roadmap = [] } = props;
  const yearsRef = useRef<HTMLDivElement | null>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [isSticky, setSticky] = useState(false);
  const [selectedYear, setSelectedYear] = useState(() => {
    const currentYear = new Date().getFullYear().toString();

    if (roadmap.find(({ year }) => year === currentYear)) {
      return currentYear;
    }

    return roadmap[roadmap.length - 1].year;
  });

  useEffect(() => {
    const element = yearsRef.current;

    if (!element) {
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => setSticky(entry.isIntersecting),
      { rootMargin: '0px 0px -99% 0px' }
    );

    observer.observe(element);

    return () => {
      observer.unobserve(element);
    };
  }, []);

  const visibleSections = roadmap
    .find(({ year }) => year === selectedYear)
    ?.sections ?? [];

  return (
    <Grid ref={contentRef}>
      <YearsWrapper
        isSticky={isSticky}
        ref={yearsRef}
      >
        <YearsList role={'tablist'}>
          {roadmap.map(({ year }) => (
            <Year
              aria-selected={year === selectedYear}
              key={year}
              onClick={event => {
                const eventTarget = event.target;

                setSelectedYear(year);
                requestAnimationFrame(() => {
                  if (yearsRef.current) {
                    yearsRef.current.scrollTo({
                      behavior: 'smooth',
                      // 100 is an offset to make the previous year also visible.
                      left: eventTarget.offsetLeft - 100,
                      top: 0
                    });
                  }

                  if (contentRef.current) {
                    contentRef.current.scrollIntoView({
                      behavior: 'smooth',
                      block: 'start'
                    });
                  }
                });
              }}
              role={'tab'}
              type={'button'}
            >
              {year}
            </Year>
          ))}
        </YearsList>
      </YearsWrapper>

      <YearContent role={'tabpanel'}>
        {visibleSections.map((section, index) => {
          return (
            <Fragment key={section.id}>
              <SectionMarker
                isLast={index === visibleSections.length - 1}
                status={section.status}
              >
                <Type.H4 fontWeight={500}>
                  {section.title}
                </Type.H4>

                <MarkerIcon
                  icon={statusIcons[section.status]}
                  size={units(3)}
                />
              </SectionMarker>

              <SectionEntries>
                {section.entries.map(entry => (
                  <SectionEntry key={entry.id}>
                    <SectionEntryText>
                      {entry.title}
                    </SectionEntryText>

                    <EntryBadge colorTheme={entry.badgeColorTheme}>
                      {entry.badgeText}
                    </EntryBadge>
                  </SectionEntry>
                ))}
              </SectionEntries>
            </Fragment>
          );
        })}
      </YearContent>
    </Grid>
  );
}

/**
 * Export `RoadmapTabs` component.
 */

export default RoadmapTabs;
