// @flow

/**
 * Module dependencies.
 */

import { Container } from 'components/core/layout';
import type { FixedImage } from 'types/image';
import { Image } from 'components/core/image';
import { Type } from 'react-components/typography';
import { color, media, units } from 'react-components/styles';
import RawHtml from 'react-components/raw-html';
import React, { type Node } from 'react';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  lastUpdated: {|
    date: string,
    title: string
  |},
  lead: string,
  separatorImage: FixedImage,
  title: string
|};

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  ${media.min('md')`
    display: grid;
    grid-template-areas: '. content .';
    grid-template-columns: 1fr 8fr 1fr;
  `}
`;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.header`
  display: grid;
  grid-area: content;
  grid-template-areas:
    'title'
    'lead'
    'lastUpdated';
  padding-bottom: ${units(8)};
  padding-top: ${units(18)};

  ${media.min('md')`
    align-items: end;
    grid-template-areas:
      'title title'
      'lead  lastUpdated';
    padding-bottom: ${units(4)};
    padding-top: ${units(25)};
  `}
`;

/**
 * `LastUpdated` styled component.
 */

const LastUpdated = styled.div`
  grid-area: lastUpdated;
  padding-top: ${units(5)};

  ${media.min('md')`
    padding-top: 0;
    text-align: right;
  `}
`;

/**
 * `SeparatorWrapper` styled component.
 */

const SeparatorWrapper = styled.div`
  display: none;

  ${media.min('md')`
    display: block;
    padding-top: ${units(9)};
  `}
`;

/**
 * `RoadmapHeader` component.
 */

function RoadmapHeader(props: Props): Node {
  const { lastUpdated, lead, separatorImage, title } = props;

  return (
    <Container>
      <Grid>
        <Wrapper>
          <Type.H1 gridArea={'title'}>
            {title}
          </Type.H1>

          <Type.H5
            alignSelf={'end'}
            as={'div'}
            gridArea={'lead'}
            paddingTop={units(4)}
          >
            <RawHtml>
              {lead}
            </RawHtml>
          </Type.H5>

          <LastUpdated>
            <Type.Label
              as={'p'}
              color={color('blue500')}
              fontWeight={600}
              textTransform={'uppercase'}
            >
              {lastUpdated?.title}
            </Type.Label>

            <Type.Paragraph fontWeight={600}>
              {lastUpdated?.date}
            </Type.Paragraph>
          </LastUpdated>

          <SeparatorWrapper>
            <Image fixed={separatorImage} />
          </SeparatorWrapper>
        </Wrapper>
      </Grid>
    </Container>
  );
}

/**
 * Export `RoadmapHeader` component.
 */

export default RoadmapHeader;
